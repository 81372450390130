import request from '../utils/request'
import baseUrl from './baseUrl'

export const lineList = (data) => request({
  url: baseUrl + '/travel/selectForBack',
  method: 'POST',
  data
})
export const addLine = (data) => request({
  url: baseUrl + '/travel/add',
  method: 'POST',
  data
})
//编辑线路
export const editLine = (data) => request({
  url: baseUrl + '/travel/add',
  method: 'POST',
  data
})
// 查看详情
export const lineDetails = (data) => request({
  url: baseUrl + '/travel/selectForId',
  method: 'POST',
  data
})
//上下架删除
export const changeStatus = (data) => request({
  url: baseUrl + '/travel/modifyStatus',
  method: 'POST',
  data
})
//修改排序
export const changeSort = (data) => request({
  url: baseUrl + '/travel/modifyDept',
  method: 'POST',
  data
})
//一键复制
export const addByCody = (data) => request({
  url: baseUrl + '/travel/addCopy',
  method: 'POST',
  data
})
//批量热门
export const addHot = (data) => request({
  url: baseUrl + '/travel/modifyHot',
  method: 'POST',
  data
})