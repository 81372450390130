import request from '../utils/request'
import baseUrl from './baseUrl'

export const roomList = (data) => request({
  url: baseUrl + '/room/selectForBack',
  method: 'POST',
  data
})
export const addRoom = (data) => request({
  url: baseUrl + '/room/add',
  method: 'POST',
  data
})
export const editRoom = (data) => request({
  url: baseUrl + '/room/add',
  method: 'POST',
  data
})
//查看详情 
export const roomDetails = (data) => request({
  url: baseUrl + '/room/selectForId',
  method: 'POST',
  data
})
//修改排序 
export const changeSort = (data) => request({
  url: baseUrl + '/room/modifyDept',
  method: 'POST',
  data
})
//上下架删除 
export const changeStatus = (data) => request({
  url: baseUrl + '/room/modifyStatus',
  method: 'POST',
  data
})
//根据酒店ID查询所有客房 
export const selectAllByHotelId = (data) => request({
  url: baseUrl + '/room/selectAllByHotelId',
  method: 'POST',
  data
})
//查询月价格 
export const roomPriceList = (data) => request({
  url: baseUrl + '/roomPrice/selectForBack',
  method: 'POST',
  data
})
//一键复制
export const addByCody = (data) => request({
  url: baseUrl + '/room/addByCody',
  method: 'POST',
  data
})
export const tj = (data) => request({
  url: baseUrl + '/room/modifyTJ',
  method: 'POST',
  data
})

// 连住优惠
//活动列表
export const getActiveList = (data) => request({
  url: baseUrl + '/connectInfo/selectForBack',
  method: 'POST',
  data
})
//活动列表-启用/使失效
export const modifyActiveStatus = (data) => request({
  url: baseUrl + '/connectInfo/modifyStatus',
  method: 'POST',
  data
})
//活动列表-删除
export const deleteActive = (data) => request({
  url: baseUrl + '/connectInfo/deleteById',
  method: 'POST',
  data
})
//活动列表-修改排序
export const modifyActiveDept = (data) => request({
  url: baseUrl + '/connectInfo/modifyDept',
  method: 'POST',
  data
})
//活动列表-添加/修改
export const addActive = (data) => request({
  url: baseUrl + '/connectInfo/add',
  method: 'POST',
  data
})
//活动列表-查看详情
export const getActiveDetail = (data) => request({
  url: baseUrl + '/connectInfo/selectForId',
  method: 'POST',
  data
})
// 商品设置
// 客房列表
export const getRoomList = (data) => request({
  url: baseUrl + '/connectRoom/selectForBack',
  method: 'POST',
  data
})
// 查询所有客房列表
export const getRoomListAll  = (data) => request({
  url: baseUrl + '/room/selectForConnect',
  method: 'POST',
  data
})
// 添加活动客房
export const addActiveRoom  = (data) => request({
  url: baseUrl + '/connectRoom/add',
  method: 'POST',
  data
})
// 添加活动客房
export const modifyRoomStock  = (data) => request({
  url: baseUrl + '/connectRoom/modifyStock',
  method: 'POST',
  data
})
// 删除活动客房
export const deleteRoom  = (data) => request({
  url: baseUrl + '/connectRoom/deleteById',
  method: 'POST',
  data
})
// 启用/禁用活动客房
export const modifyActiveRoomStatus  = (data) => request({
  url: baseUrl + '/connectRoom/modifyStatus',
  method: 'POST',
  data
})


// 提前预定优惠
//活动列表
export const getActiveListReserve = (data) => request({
  url: baseUrl + '/advanceInfo/selectForBack',
  method: 'POST',
  data
})
//活动列表-启用/使失效
export const modifyActiveStatusReserve = (data) => request({
  url: baseUrl + '/advanceInfo/modifyStatus',
  method: 'POST',
  data
})
//活动列表-删除
export const deleteActiveReserve = (data) => request({
  url: baseUrl + '/advanceInfo/deleteById',
  method: 'POST',
  data
})
//活动列表-修改排序
export const modifyActiveDeptReserve = (data) => request({
  url: baseUrl + '/advanceInfo/modifyDept',
  method: 'POST',
  data
})
//活动列表-添加/修改
export const addActiveReserve = (data) => request({
  url: baseUrl + '/advanceInfo/add',
  method: 'POST',
  data
})
//活动列表-查看详情
export const getActiveDetailReserve = (data) => request({
  url: baseUrl + '/advanceInfo/selectForId',
  method: 'POST',
  data
})

// 商品设置
// 客房列表
export const getCommodityList = (data) => request({
  url: baseUrl + '/advanceProd/selectForBack',
  method: 'POST',
  data
})
// 添加活动客房
export const addActiveCommodity  = (data) => request({
  url: baseUrl + '/advanceProd/add',
  method: 'POST',
  data
})
// 修改库存
export const modifyCommodityStock  = (data) => request({
  url: baseUrl + '/advanceProd/modifyStock',
  method: 'POST',
  data
})
// 删除活动客房
export const deleteCommodity = (data) => request({
  url: baseUrl + '/advanceProd/deleteById',
  method: 'POST',
  data
})
// 启用/禁用活动客房
export const modifyActiveCommodityStatus  = (data) => request({
  url: baseUrl + '/advanceProd/modifyStatus',
  method: 'POST',
  data
})
// 查询所有客房
export const checkRoomList  = (data) => request({
  url: baseUrl + '/room/selectForAdvance',
  method: 'POST',
  data
})
// 查询所有门票
export const checkTicketList  = (data) => request({
  url: baseUrl + '/ticket/selectForAdvance',
  method: 'POST',
  data
})
// 查询所有套餐
export const checkTravelList  = (data) => request({
  url: baseUrl + '/travel/selectForAdvance',
  method: 'POST',
  data
})
// 查询套餐下的线路
export const travelSpecsList  = (data) => request({
  url: baseUrl + '/travelSpecs/selectForAdvance',
  method: 'POST',
  data
})
export const wanlvRoomShow  = (data) => request({
  url: baseUrl + '/room/modifyWlShow',
  method: 'POST',
  data
})